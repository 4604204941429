const __request = require(`./__request/__request_contentType_json`)

const wallet_order_alipay_fundBatch_recharge_pay = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/wallet/order/alipay/fundBatch/rechargePay",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = wallet_order_alipay_fundBatch_recharge_pay;