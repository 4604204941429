import moment from 'moment';
import wallet_order_alipay_fundBatch_rechargeOrderList from "@/lib/data-service/default/wallet_order_alipay_fundBatch_rechargeOrderList";
import wallet_order_alipay_fundBatch_recharge_pay from "@/lib/data-service/default/wallet_order_alipay_fundBatch_recharge_pay";
import wallet_order_alipay_fundBatch_recharge_refresh from "@/lib/data-service/default/wallet_order_alipay_fundBatch_recharge_refresh";
import wallet_order_alipay_fundBatch_recharge_cancel from "@/lib/data-service/default/wallet_order_alipay_fundBatch_recharge_cancel";
import wallet_order_alipay_fundBatch_billEreceiptApply from "@/lib/data-service/default/wallet_order_alipay_fundBatch_billEreceiptApply";

export default {
    data() {
        return {
            loading: false,
            form: {
                paymentTimeBegin: '',
                paymentTimeEnd: '',
                successTimeBegin: '',
                successTimeEnd: '',
                orderNo: '',
                orderStatus: '',
                flowNo: '',
                transferNo: '',
                currentPage: 1,
                pageSize: 10,
            },
            pager: {
                total: 0,
                pageSizes: [10, 20, 30],
            },
            list: [],
            orderStatusList: [
                {
                    value: null,
                    name: '全部',
                },
                {
                    value: 0,
                    name: '待支付',
                },
                {
                    value: 1,
                    name: '支付失败',
                },
                {
                    value: 2,
                    name: '支付成功',
                },
                {
                    value: 3,
                    name: '已取消',
                },
                {
                    value: 4,
                    name: '已入账',
                },
                {
                    value: 5,
                    name: '入账失败',
                },
            ],
            paymentDate: '',
            successDate: '',
        };
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            this.getList();
        },

        //获取列表
        async getList() {
            let res = await wallet_order_alipay_fundBatch_rechargeOrderList(this.form);
            this.list = res.pageResult.pageData;
            this.pager.total = res.pageResult.totalCount;
        },

        reset() {
            this.form = {
                paymentTimeBegin: '',
                paymentTimeEnd: '',
                successTimeBegin: '',
                successTimeEnd: '',
                orderNo: '',
                orderStatus: '',
                flowNo: '',
                transferNo: '',
                currentPage: 1,
                pageSize: 10,
            };
            this.paymentDate = '';
            this.successDate = '';
            this.getList();
        },

        //改变支付日期
        changePaymentDate(newVal) {
            this.form.paymentTimeBegin = moment(newVal[0]).format('YYYY-MM-DD') + ' 00:00:00';
            this.form.paymentTimeEnd = moment(newVal[1]).format('YYYY-MM-DD') + ' 23:59:59';
        },
        //改变入账日期
        changeSuccessDate(newVal) {
            this.form.successTimeBegin = moment(newVal[0]).format('YYYY-MM-DD') + ' 00:00:00';
            this.form.successTimeEnd = moment(newVal[1]).format('YYYY-MM-DD') + ' 23:59:59';
        },

        //换页
        changeCurrentPage(currentPage) {
            this.form.currentPage = currentPage;
            this.getList();
        },

        //改变每页显示最多数据量
        changePageSize(pageSize) {
            this.form.pageSize = pageSize;
            this.changeCurrentPage(1);
        },
        payOrder(orderNo){
            this.loading = true;
            let params={
                orderNo:orderNo
            }
            wallet_order_alipay_fundBatch_recharge_pay(params).then(res => {
                this.loading = false;
                var neww = window.open('', "_blank");
                if (neww) {
                    var doc = neww.document;
                    doc.open();
                    doc.write(res.payFormHtml);
                    doc.close();
                }
            }).catch(err => {
                this.loading = false;
            })
        },
        refreshOrder(orderNo){
            this.loading = true;
            this.$confirm('此操作将刷新该订单状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params={
                    orderNo: orderNo
                }
                wallet_order_alipay_fundBatch_recharge_refresh(params).then(res => {
                    this.loading = false;
                    this.getList();
                }).catch(err => {
                    this.loading = false;
                })
            }).catch(() => {
            });
        },
        cancelOrder(orderNo){
            this.loading = true;
            this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params={
                    orderNo: orderNo
                }
                wallet_order_alipay_fundBatch_recharge_cancel(params).then(res => {
                    this.loading = false;
                    this.getList();
                }).catch(err => {
                    this.loading = false;
                })
            }).catch(() => {
            });
        },
        billEreceiptApply(orderNo){
            this.loading = true;
            this.$confirm('此操作将申请电子回单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params={
                    orderNo: orderNo
                }
                wallet_order_alipay_fundBatch_billEreceiptApply(params).then(res => {
                    this.loading = false;
                    this.getList();
                }).catch(err => {
                    this.loading = false;
                })
            }).catch(() => {
            });
        },
        billEreceiptDownload(val){
            if (val !== null && val !== '') {
                window.open(val);
            } else {
                this.$message({
                    type: "warning",
                    message: '下载失败，请稍候再试'
                });
            }
        },
    }
};